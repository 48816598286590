module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-remark-images-virtual-d661b7d092/0/cache/gatsby-remark-images-npm-7.13.1-bfe6d0ffe3-fc1032bb41.zip/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":750,"linkImagesToOriginal":false,"wrapperStyle":"margin-bottom: 1.0725rem;","showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-4bcc00f7e2/0/cache/gatsby-plugin-manifest-npm-5.13.1-2036e9f84e-45d59a4aa5.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Cajero","short_name":"Cajero","description":"Cajero es un proveedor de servicios Bitcoin en Perú. Facilitamos la compra, venta o envío de dinero con Bitcoin y otros activos digitales.","lang":"es","start_url":"/","background_color":"#fff","theme_color":"#f9a136","display":"standalone","icon":"static/images/cajero-icono.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7a4725821cca410fa96d117aab9396c4"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-tagmanager-virtual-c11eaa566d/0/cache/gatsby-plugin-google-tagmanager-npm-5.13.1-f82e0c3055-e4d7e6e0c9.zip/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NDC4Z6Z","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"enableWebVitalsTracking":true,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-catch-links-virtual-3fe4f7cb73/0/cache/gatsby-plugin-catch-links-npm-5.13.1-d0715a6524-eb88878106.zip/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-offline-virtual-0fc8c2d784/0/cache/gatsby-plugin-offline-npm-6.13.2-1c7a19e4d8-ca1006e2bd.zip/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-fontawesome-css-virtual-047560f41d/0/cache/gatsby-plugin-fontawesome-css-npm-1.2.0-8138f32ad8-c0419d163f.zip/node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-e632516912/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
